import { Media, mbSize, typography } from '@renderbus/common/theme'
import styled from 'styled-components'
import ArrowWhite from '../images/download/arrow-white.png'
import ArrowGreen from '../images/download/arrow-green.png'
import ArrowBlue from '../images/download/arrow-blue.png'
import { Section } from '@renderbus/common/components'

export const BannerWrap = styled.div`
  height: 800px;
  background: #0b0d0c;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  ${Media.lessThan(Media.small)} {
    height: unset;
    padding-bottom: ${mbSize(120)};
  }
`

export const DropdownWrap = styled.div`
  display: none;
  position: absolute;
  top: 51px;
  padding: 20px 20px;
  width: 240px;
  background-color: #282828;
  border-radius: 10px;
  border: 1px solid #505050;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
`

export const BannerBgWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  > img {
    width: 100%;
    height: 100%;
  }
  ${Media.lessThan(Media.small)} {
    height: ${mbSize(480)};
  }
`

export const BannerTitle = styled.h1`
  font-size: ${typography.h1};
  font-weight: bold;
  text-align: center;
  line-height: 48px;
  color: #fff;
  margin-bottom: 31px;
  margin-top: 139px;
  z-index: 1;
  ${Media.lessThan(Media.small)} {
    margin-top: ${mbSize(159)};
    margin-bottom: ${mbSize(20)};
    font-size: ${mbSize(54)};
    line-height: unset;
  }
`

export const BannerSubTitle = styled.div`
  text-align: center;
  color: #999999;
  font-size: 16px;
  padding-bottom: 67px;
  z-index: 1;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(22)};
    padding-bottom: ${mbSize(100)};
  }
`

export const DownloadTabsWrap = styled.div`
  display: flex;
  gap: 50px;
  z-index: 1;
  width: 100%;
  max-width: 1380px;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    gap: ${mbSize(58)};
  }
`

export const DownloadContent = styled.div`
  display: flex;
  gap: 80px;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    align-items: center;
    gap: ${mbSize(30)};
  }
`

export const DownloadContentImg = styled.div`
  flex: 1;
  /* max-width: 542px; */
  max-width: 720px;
  > img {
    width: 100%;
  }
  ${Media.lessThan(Media.small)} {
    flex: 1;
    max-width: unset;
    width: ${({ mbWidth }) => mbSize(mbWidth || 650)};
    height: ${({ mbHeight }) => mbSize(mbHeight || 470)};
  }
`
export const LinkText = styled.div`
  color: #999999;
  font-size: 18px;
  margin-bottom: 80px;
  .to-renderings {
    color: #2789ff;
    text-decoration: underline;
  }
  .to-animation {
    color: #13cb75;
    text-decoration: underline;
  }
`
export const DownloadInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${Media.lessThan(Media.small)} {
    align-items: center;
  }
`

export const DownloadInfoTitle = styled.h2`
  font-weight: bold;
  font-size: 36px;
  color: #ffffff;
  margin-bottom: 8px;
  margin-top: 0;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(48)};
    margin-bottom: ${mbSize(19)};
    margin-top: ${mbSize(29)};
  }
`

export const DownloadInfoTitleSub = styled.div`
  font-size: 16px;
  color: #999999;
  a:hover {
    color: #13cb75;
  }
  a.is-picture:hover {
    color: #2789ff;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${({ mbFontSize }) => mbSize(mbFontSize || 24)};
  }
`

export const DownloadButtonWrap = styled.div`
  display: flex;
  gap: 40px;
  margin-top: 35px;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    align-items: center;
    margin-top: ${mbSize(50)};
  }
`

export const DownloadNote = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: #999999;
  line-height: 20px;
  margin-top: 30px;
  display: flex;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    align-items: center;
    font-size: ${mbSize(22)};
    margin-top: ${mbSize(60)};
    .hide-mb {
      display: none;
    }
  }
`

export const DownloadItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #999999;
  font-weight: 300;
  ${Media.lessThan(Media.small)} {
    gap: ${mbSize(20)};
  }
`

export const DownloadSystemText = styled.div`
  font-size: ${mbSize(30)};
  line-height: ${mbSize(30)};
  font-weight: bold;
  color: ${({ isBlue }) => (isBlue ? '#2676FF' : '#13cb75')};
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    width: ${({ imgWidth }) => mbSize(imgWidth)};
    height: ${({ imgHeight }) => mbSize(imgHeight)};
    margin-right: ${mbSize(11)};
  }
`

export const DownloadButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: ${({ width }) => width || '280px'};
  height: 50px;
  background: ${({ isBlue }) =>
    isBlue
      ? 'radial-gradient(circle at 100%, #3BC1ED 0%, #2676FF 100%);'
      : 'radial-gradient(circle at 100%, #13cb75 0%, #12cc9a 100%)'};
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
  :hover {
    box-shadow: 0px 10px 30px 10px ${({ isBlue }) => (isBlue ? '#1256cc4d' : '#13cb7533')};
    ${DropdownWrap} {
      display: block;
    }
  }
  > img {
    width: ${({ imgWidth }) => imgWidth};
    height: ${({ imgHeight }) => imgHeight};
    margin-right: 10px;
  }
`

export const DownloadButtonWrapperXgt = styled.div`
  display: flex;
  gap: 30px;
`

export const DownloadButtonOutlined = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 278px;
  height: 50px;
  border-radius: 25px;
  border: 1px solid #2676ff;
  font-weight: bold;
  font-size: 20px;
  color: #2676ff;
  line-height: 32px;
  cursor: pointer;
  transition: all 0.3s;
  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  &:hover {
    color: #5996ff;
    border: 1px solid #5996ff;
  }
`

export const DownloadButtonMin = styled(DownloadButton)`
  width: 200px;
  font-size: 16px;
  margin-bottom: 14px;
  height: 40px;
  &:last-child {
    margin-bottom: 0px;
  }
`

export const DownloadVersion = styled.div`
  font-size: 12px;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(22)};
    text-align: center;
  }
`

export const DownloadFeatures = styled.div`
  font-size: 14px;
  ${Media.lessThan(Media.small)} {
    margin: 0 ${mbSize(40)};
    font-size: ${mbSize(22)};
    line-height: ${mbSize(36)};
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: ${mbSize(10)};
    div:nth-child(1) {
      flex-basis: 100%;
      display: flex;
      justify-content: center;
    }
  }
`

export const DownloadFeaturesXgt = styled.div`
  font-size: 14px;
  div {
    width: 200px;
  }
  div.items {
    display: flex;
    flex-direction: column;
    column-gap: 30px;
    flex-wrap: wrap;
    height: 100px;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(22)};
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
      width: unset;
    }
    div.items {
      width: ${mbSize(612)};
      display: flex;
      flex-direction: row;
      justify-content: center;
      column-gap: ${mbSize(20)};
      flex-wrap: wrap;
      height: unset;
    }
  }
`

export const DownloadLinksXgt = styled.div`
  display: flex;
  gap: 36px;
`

export const DownloadNoteXgt = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(22)};
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${mbSize(30)};
  }
`

export const DownloadLink = styled.a`
  transition: all 0.2s;
  cursor: pointer;
  font-size: 16px;
  color: #cccccc;
  margin-top: 30px;
  ::after {
    content: '';
    display: inline-block;
    width: 14px;
    height: 11px;
    margin-left: 10px;
    background-image: url(${ArrowWhite});
    background-size: 100% 100%;
  }
  &.download {
    ::after {
      transform: rotate(90deg);
    }
  }
  :hover {
    color: #13cb75;
    ::after {
      background-image: url(${ArrowGreen});
    }
    &.renderings {
      color: #2789ff;
      ::after {
        background-image: url(${ArrowBlue});
      }
    }
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(24)};
    margin-top: ${mbSize(80)};
    ::after {
      width: ${mbSize(24)};
      height: ${mbSize(19)};
      margin-left: ${mbSize(10)};
    }
  }
`

export const VideoWrap = styled.div`
  background: #0b0d0c;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const VideoSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 80px;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const SectionTitleLocal = styled.h3`
  text-align: center;
  color: #ffffff;
  font-weight: 500;
  font-size: 30px;
  letter-spacing: 5px;
`

export const Video = styled.video`
  display: block;
  width: 960px;
  height: 540px;
  margin: 0 auto;
`

export const DownloadTip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${mbSize(420)};
  height: ${mbSize(60)};
  background: #99999966;
  border-radius: ${mbSize(30)};
  margin: ${mbSize(100)} 0;
  font-size: ${mbSize(30)};
  color: #cccccc;
  > img {
    width: ${mbSize(27)};
    height: ${mbSize(28)};
    margin-right: ${mbSize(10)};
  }
`

export const DownloadButtonWrapDropDown = styled(DownloadButtonWrap)`
  ${DropdownWrap} {
    display: block;
  }
`
